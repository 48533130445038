import {
  AccountType,
  AccrualType,
  BonusResultType,
  CompositeProductType,
  ConfirmStatus,
  EmployeeType,
  EncashmentOperationType,
  FeedbackType,
  FiscalProvider,
  InvoiceStatus,
  InvoiceType,
  NotificationType,
  OrderStatus,
  PaymentApprovalStatus,
  ProductCategoryType,
  RecipeType,
} from './enum';
import { format, parse } from 'date-fns';
import { InjectionToken } from '@angular/core';
import {
  AbstractGroupModel,
  AddressModel,
  AppEventType,
  AtolFiscalResult,
  CallShortModel, CallStatus, ChildrenModel,
  CityModel,
  ClientShortModel,
  ComponentType,
  ConditionType,
  CountryModel,
  Currency,
  DecorModel,
  DeliveryRangeModel,
  DeliveryType,
  DiscountCondition,
  DiscountResultModel, DocumentModel,
  Entity,
  FileModel, Gender,
  InscriptionModel,
  KindType,
  Language, MaritalStatus, MembershipModel,
  OrderAction,
  OrganizationModel,
  PaymentInfoModel,
  PaymentModel,
  PaymentType,
  PayModel,
  PriceShortModel,
  ProductInfoShortModel,
  ProductInfoType,
  ProductShortModel,
  ProductStatus,
  ReceiptProductModel, SalePriceModel,
  SaleProductModel,
  SourceSystem,
  StoreBasicModel,
  StorePaymentType,
  StoreWorkTimeModel,
  UiLabel,
  UnitOfMeasureModel,
  UserBasicModel,
  WebkassaFiscalResult, WorkPeriodModel
} from './vn-api';

export const HX_COMPONENT_NAME = new InjectionToken<ComponentType>('name of component, example: cc or manager');
export const HX_APP_VERSION = new InjectionToken<string>('version of app, example: 20211101');
export const HX_ENV_MODE = new InjectionToken<boolean>('production');
export const HX_KEYCLOAK = new InjectionToken<{ url: string, realm: string, clientId: string }>('keycloak configs');
export const HX_GOOGLE_MAPS_API_KEY = new InjectionToken<string>('google map api key');

interface Base {
  id: number;
  createDate: string;
  modifyDate: string;
  enabled: boolean;
  removed: boolean;
}

export interface SmsInfo {
  messageId: string;
  status: string;
  sendDate: string;
  lastStatusDate: string;
  segmentCount: number;
}

export interface AppEventFull {
  id: number;
  createDate: string;
  type: AppEventType;
  data: any;
  metaData: any;
  actor: number;
  actorFullname: string;
  containerId: number;
  containerClass: string;
  storeId: number;
  info: string;
}

export interface Notification {
  id: number;
  createDate: string;
  modifyDate: string;
  enabled: boolean;
  removed: boolean;
  seenDate?: number;
  readDate?: number;
  notificationId: number;
  phone: string;
  sendType: string;
  topic: string;
  text: string;
  creator: string;
  type: NotificationType;
  priority: number;
  data?: any;
}

export interface ProductCategoryModel {
  id: number;
  enabled: boolean;
  title: UiLabel;
  seq: number;
  type?: ProductCategoryType;
  brandId: number;
  components: ComponentType[];
}


export interface ClientPropertyModel {
  id: number;
  brandId: number;
  brandTitle: string;
  clientId: number;
  note: string;
  blacklisted: boolean;
  vip: boolean;
}

export interface CityDeliveryMap {
  url: string; // yandex delivery map url
  defaultMapType: 'dgis' | 'yandex' | 'google' | 'none';
  deliveryMap: {
    type: string;
    features: YaFeature[];
    metadata: {
      name: string;
      creator: string;
      description: string;
    };
  };
  dgisRegionId?: number;
  center?: { lat: number, lng: number };
  includeAdmDiv?: boolean;
  mapTypes?: string[];
  bbox?: string;
  bounds?: string;
}

export interface DeliveryZoneModel {
  id: number;
  brand: BrandModel;
  city: CityModel;
  zone: CityDeliveryMap;
  fromDate: string;
}

export interface YaFeature {
  id: number;
  type: 'Feature';
  geometry: YaGeometry;
  properties: {
    'fill': string,
    'stroke': string,
    'price-id': number,
    'store-id': number,
    'description': string,
    'fill-opacity': number,
    'stroke-width': string,
    'stroke-opacity': number,
    'other-stores'?: {
      'store-id': number,
      'price-id': number,
    }[]
  };
}

export interface YaGeometry {
  type: 'Polygon' | 'Point';
}

export interface YaPolygon extends YaGeometry {
  type: 'Polygon';
  coordinates: number[][][];
}

export interface YaPoint extends YaGeometry {
  type: 'Point';
  coordinates: number[];
}

export interface GroupModel extends AbstractGroupModel {
  discriminator: 'G' | 'S' | 'D';
}

export interface FiscalInfoModel {
  enabled: boolean;
  provider: FiscalProvider;
  deviceBrand?: string;
  online: boolean;
  taxationType?: string; // atol
  taxType?: string; // atol
}

export function toSnakeCase(name: string, separator?: string): string {
  separator = separator || '_';
  return name.replace(/[A-Z]/g, (letter, pos) => (pos ? separator : '') + letter.toLowerCase());
}

export function toCamelCase(str: string): string {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export interface GroupComponentModel {
  group: AbstractGroupModel;
  city: CityModel;
  components: ComponentType[];
}

export interface DscBonusModel {
  products: DscBonusProductModel[];
}

export interface DscBonusProductModel {
  productDefinitionId: number;
  priceAmount: number;
  value: number;
  amount: number;
  resultType: BonusResultType;
}

export interface DscFixedProductPriceModel {
  productInfoId: number;
  priceAmount: number;
  value: number;
}

export interface User {
  phone: string;
  asteriskId: string;
  fullname: string;
  role: string;
}

export interface ClientBasicModel {
  id: number;
  createDate: string;
  phone: string; // TODO @deedarb phone could be undefined
  fullname?: string;
  birthDate?: string;
  email?: string;
  countryId?: number;
}

export interface SpendingFullModel {
  id: number;
  organization: OrganizationModel;
  store: StoreBasicModel;
  spendCategory: SpendCategoryModel;
  total: number;
  date: string;
  incomingNumber: string;
  paid: string;
  note: string;
  currency: Currency;
  products: StorageInvoiceProductModel[];
}

export type LocalDate = string;

export interface SpendingModel {
  id: number;
  organizationId: number;
  storeId: number;
  spendCategoryId: number;
  total: number;
  date: LocalDate;
  incomingNumber?: string;
  paidDate?: LocalDate
}

export interface SpendCategoryModel {
  id: number;
  enabled: boolean;
  createDate: string;
  name: UiLabel;
  parentId: number;
  brandId: number;
}

export interface SpendCategoryFullModel {
  id: number;
  enabled: boolean;
  createDate: string;
  name: UiLabel;
  parent?: SpendCategoryModel;
  brand?: BrandModel;
}

export interface InvoiceModel {
  id: number;
  fromStore?: StoreBasicModel;
  toStore?: StoreBasicModel;
  senderUser: UserBasicModel;
  receiveUser: UserBasicModel;
  receiveDate: string;
  sendDate: string;
  status: InvoiceStatus;
  sourceSystem: SourceSystem;
  description: string;
  type: InvoiceType;
  products: InvoiceProductModel[];
  files: FileModel[];
}

export interface InvoiceProductModel {
  id: number;
  productInfoId: number;
  productInfoTitle: UiLabel;
  amount: number;
  containerId: number;
  containerClass: ClassName;
  date: string;
  status: ProductStatus;
}

export interface OrderAllResponse {
  id: number;
  date: string;
  uniqueNumber: string;
  status: OrderStatus;
  phone: string;
  deliveryType: DeliveryType;
  clientType: Entity;
  address: string;
  admDiv: string;
  entrance: string;
  floor: string;
  flat: string;
  total: number;
  subTotal: number;
  createDate: string;
  fileExist: boolean;
  decorExist: boolean;
  decorText: string;
  action: OrderAction;
  note: string;
  paid: boolean;
  from: SourceSystem;
  seen: number[];
  checkingAccountFileId: number;
  important: boolean;
  fiscalNumber: string;
  fiscalDate: string;
  hasPrepaid: boolean;
  revoked: boolean;
  city: CityModel;
  client: ClientShortModel;
  products: ProductAllShort[];
  store: StoreBasicModel;
  storePaymentType: StorePaymentType;
  deliveryRange: DeliveryRangeModel;
  deliveryRangeId: number;
  deliveryUser: UserBasicModel;
  payment: PaymentModel;
  lastModifyEventDate: string;
}

export interface ProductAllShort {
  title: UiLabel;
  amount: number;
  priceValue: number;
  value: number;
  price: PriceShortModel;
  status: ProductStatus;
  weight: number;
  type: string;
  productInfoId: number;
  paymentDate: string;
}

export interface Order {
  id: number;
  client?: number;
  deliveryType?: DeliveryType;
  note: string;
  date: string;
  cityId: number;
  deliveryRangeId?: number;
  cartRangeId?: number;
  storeId: number;
  products: ProductShortModel[];
  discounts: DiscountResultModel[];
  files: number[];
  decorText: string;
  phone?: string;
  paid: boolean;
  recipientClientId?: number;
  recipientClientPhone?: string;
  from: SourceSystem;
  debt: boolean;
  important: boolean;
  fiscalDate: string;
  taxSum: number;
  address?: string;
  admDiv?: string;
  entrance?: string;
  floor?: string;
  flat?: string;
  deliveryLatitude?: number;
  deliveryLongitude?: number;
  addressNote?: string;
  total: number;
  subTotal: number;
  currency: Currency;
  orderDate?: string;
  orderTime?: string;
  pickupTime?: string;
  clientType: Entity;
  rejectionId?: number;
  referrer?: string;
  withoutReceipt: boolean;
}

export interface CommonError {
  data: {
    event?: {
      actor: string;
      actorFullName: string;
    },
    order?: ErrorOrder;
    fields: ErrorMessage[];
  };
  uiMessages?: ErrorMessage[];
  code: number;
  message: string;
  status: string;
}

export interface ErrorOrder {
  store: string;
  product: string[];
  actorFullName: string;
}

export interface HxError {
  code: number;
  id: string;
  message: string;
  description?: string;
  data?: any;
}

export class UiError extends Error {
  data?: any;

  constructor(message: string, data?: any) {
    super(message);
    this.data = data;
  }
}

export interface ErrorMessage {
  field: string;
  message: string;
}

export interface ErrorResponse {
  code: 413 | 422 | 577 | 576 | 575 | 574 | 567 | 578;
  id: string;
  data?: any;
  message: string;
}

export interface CalculateResponse {
  order: DscOrder;
  results: DiscountResultModel[];
}

export interface DiscountsResponse {
  otpVerified: boolean;
  otpRequired: boolean;
  results: DiscountResultModel[];
}

export interface PromoCodeModel extends Base {
  modifyUserId: number;
  orderId: number;
  code: string;
  confirmStatus: ConfirmStatus;
  confirmUser: UserBasicModel;
  creatorUser: UserBasicModel;
  confirmRequired: boolean;
}

interface DscOrder {
  otpVerified: boolean;
  otpRequired: boolean;
  total: number;
  subTotal: number;
}

export interface EmployeeCondition extends DiscountCondition {
  type: 'EMPLOYEE';
  debtAllowed: boolean;
}

export function toFixed(val: number, fraction = 2): number {
  return Number(val.toFixed(fraction));
}

export function personShortName(value: string): string {
  if (!value) {
    return '';
  }
  return value.split(' ')
    .map((item, index) => item && item !== 'null' && item !== 'undefined' ? index === 0 ? item : `${item[0].toUpperCase()}.` : '')
    .join(' ');
}

export function isProductDiscounted(productId: number, discounts: DiscountResultModel[]): boolean {
  if (!discounts) {
    return false;
  }
  return discounts.find(dsc => dsc.productId === productId && dsc.enabled && !dsc.removed) !== undefined;
}

// usage declOfNum(2, ['оценка', 'оценок', 'оценок'])
export function declOfNum(n: number, words: string[]) {
  return words[(n % 100 > 4 && n % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5]];
}

export interface TrackingModel {
  id: number;
  orderId?: number;
  referrer?: string;
  referrerComment?: string;
  utmMedium?: string;
  utmSource?: string;
  utmCampaign?: string;
  utmId?: string;
  utmTerm?: string;
  utmContent?: string;
}

export interface OrderResponse {
  id: number;
  date: string;
  uniqueNumber: string;
  status: OrderStatus;
  action: OrderAction;
  deliveryType: DeliveryType;
  cityId: number;
  note: string;
  storeId: number;
  clientId: number;
  total: number;
  subTotal: number;
  createDate: string;
  decorText: string;
  phone: string;
  paid: boolean;
  debt: boolean;
  paymentDate: string;
  from: SourceSystem;
  important: boolean;
  fiscalNumber?: string;
  fiscalDate?: string;
  address: string;
  admDiv?: string;
  entrance?: string;
  floor?: string;
  flat?: string;
  deliveryLongitude?: number;
  deliveryLatitude?: number;
  addressNote?: string;
  storePaymentType: StorePaymentType;
  parentOrderId: number;
  currency: Currency;
  revoked: boolean;
  deliveryRangeId: number;
  cartRangeId: number;
  deliveryRange: DeliveryRangeModel;
  deliveryUser: UserBasicModel;
  client: ClientShortModel;
  recipientClient: ClientShortModel;
  calls: CallShortModel[];
  products: ProductShortModel[];
  files: FileModel[];
  remainderProductAmounts: { [priceId: number]: number; }; // map<numeric,numeric>
  clientType: Entity;
  discounts: DiscountResultModel[];
  refundUserFullname: string;
  payment: PaymentModel;
  withoutReceipt: boolean;
  archived?: boolean;
  pickupTime?: string;
  applyCoins: boolean;
}

type TYear = `${number}${number}${number}${number}`;
type TMonth = `${number}${number}`;
type TDay = `${number}${number}`;
type THours = `${number}${number}`;
type TMinutes = `${number}${number}`;
type TSeconds = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

/**
 * Represent a string like `2021-01-08`
 */
type IsoDate = `${TYear}-${TMonth}-${TDay}`;

/**
 * Represent a string like `14:42:34.678`
 */
type IsoTime = `${THours}:${TMinutes}:${TSeconds}.${TMilliseconds}`;

/**
 * Represent a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
 *
 * It is not possible to type more precisely (list every possible values for months, hours etc) as
 * it would result in a warning from TypeScript:
 *   "Expression produces a union type that is too complex to represent. ts(2590)
 */
type IsoDateTime = `${IsoDate}T${IsoTime}Z`;

export interface ReceiptModel {
  eventUuid: string;
  fiscalDetails: AtolFiscalResult | WebkassaFiscalResult;
  ofdVerifierInfo: string;
  fiscalDate: IsoDateTime;
  fiscalNumber: string;
  organizationTitle: UiLabel;
  organizationUin: string;
  uinLabel: string; // БИН ИИН ИНН
  uinName: UiLabel;
  storeName: string;
  storeTimezone: string;
  orderNumber: string;
  paid: boolean;
  cancelReason: string;
  cashier: string;
  orderAction: OrderAction;
  total: number;
  subTotal: number;
  address: string;
  storeAddress: string;
  deliveryType: DeliveryType;
  clientPhone: string;
  clientFullname: string;
  cityId: number;
  orderDate: IsoDateTime;
  courierShortName: string;
  orderId: number;
  currency: Currency;
  callCenterPhones: string[];
  storePaymentType: StorePaymentType;
  isRefund: boolean;
  discounts: DiscountResultModel[];
  products: ReceiptProductModel[];
  deliveryRange: { fromTime: IsoTime; toTime: IsoTime };
  payments: PayModel[];
  // extra fields, filled in frontend
  qr?: string; // extra field
  printDate: string;
  printman: string;
  logoUrl?: string;
  note?: string;
  withoutReceipt: boolean;
  recipientPhone: string;
  recipientFullname: string;
  ticketUrl: string;
  ofdName: string;
  ofdHost: string;
  offlineMode: boolean;
  cashboxOfflineMode: boolean;
  printLocale?: string;
}

export function receiptTicketUrl(receipt: ReceiptModel): string {
  if (receipt.fiscalDetails && receipt.fiscalDetails.type === 'atol') {
    const atol = receipt.fiscalDetails as AtolFiscalResult;
    // t=20201021T1802&s=3.00&fn=9283440300378295&i=5&fp=1755802208&n=1
    const operation = receipt.isRefund ? '2' : '1';
    // 2020-10-21T18:02:00+05:00
    const localDateTime = parse(atol.fiscalDocumentDateTime.substring(0, 16), 'yyyy-MM-dd\'T\'HH:mm', Date.now());
    const formattedDate = format(localDateTime, 'yyyyMMdd\'T\'HHmm');
    return `t=${formattedDate}&s=${atol.total}.00&fn=${atol.fnNumber}&i=${atol.fiscalDocumentNumber}&fp=${atol.fiscalDocumentSign}&n=${operation}`;
  } else if (receipt.fiscalDetails && receipt.fiscalDetails.type === 'webkassa') {
    return receipt.ticketUrl;
  }
  return '-';
}

export interface RemainderProductModel extends Remainder {
  productInfo: ProductInfoShortModel;
}

export interface CityRemainderProductModel extends RemainderProductModel {
  remainderProducts: StoreProductRemainderModel[];
}

export interface StoreProductRemainderModel extends Remainder {
  storeId: number;
}

export interface Remainder {
  forSale: boolean;
  balance: number;
  reservedPickUp: number;
  reservedDelivery: number;
  reserved: number;
  limit: number;
  plan: number;
  cart: number;
  remainder: number;
}

export interface PagedList<T> {
  count: number;
  list: Array<T>;
  included?: { [key: string]: any[] };
}

export interface DataTable<T> extends PagedList<T> {
  columns: PaymentType[];
  totalInfo: ReportTotalInfo;
}

export interface ReportTotalInfo {
  amountTotal: number;
  total: number;
  totalCostPrice: number;
  payments: PaymentInfoModel[];
}

export interface OrderEvent<T> {
  event: T;
  id: number;
}

export interface CityStoreModel {
  city: CityModel;
  stores: StoreBasicModel[];
}

export interface MessageCount {
  all: number;
  unseen: number;
  unread: number;
}

export interface CartProduct {
  price: PriceShortModel;
  productInfo: ProductInfoShortModel;
  reserved?: ProductShortModel;
  cart?: ProductShortModel;
}

export interface SaleCityProductModel {
  id: number;
  title: UiLabel;
  type: ProductInfoType;
  description: UiLabel;
  dynamicWeight: boolean;
  kind: KindType;
  conditions: SaleConditionModel[];
}

export interface SaleConditionModel {
  storeId: number;
  minOrderDay?: number;
  untilOrderTime?: string;
  receiveAfterTime?: string;
  prices: SalePriceModel[];
}

export interface FeedbackModel {
  id: number;
  createDate: string;
  modifyDate: string;
  enabled: boolean;
  removed: boolean;
  date: string;
  modifyUserId: number;
  organizationId: number;
  storeId: number;
  storeTitle: UiLabel;
  clientId: number;
  clientFullname: string;
  clientText: string;
  clientPhone: string;
  orderId: number;
  orderNumber: number;
  supportUserId: number;
  supportFullname: string;
  supportText: string;
  supportNote: string;
  supportReplyDate: string;
  supportSeenDate: string;
  callId: string;
  callcenterUserId: number;
  callcenterUserFullname: string;
  deliveryUserId: number;
  deliveryUserFullname: string;
  cashboxUserId: number;
  cashboxUserFullname: string;
  externalId: number;
  avgRating: number;
  reviews: Review[];
}

export interface Review {
  type: FeedbackType;
  date: string;
  sourceSystem: SourceSystem;
  rating: number;
  text?: string;
}

export interface ProductAddedEvent {
  item: SaleProductModel;
  price: SalePriceModel;
  product: { id?: number, isCart: boolean, isDelivery?: boolean };
}

export interface ProductSubtractedEvent {
  price: PriceShortModel;
}

export interface DateNumberRange {
  from?: number;
  to?: number;
}

export interface DateRange {
  from?: string;
  to?: string;
}

interface AbstractBaseEvent {
  eventId: string;
  eventDate: string;
  request: any;
}

export interface ProductRefundedEvent extends AbstractBaseEvent {
  orderId: number;
  refundOrderId: number;
  products: { id: number }[];
  refundSum: number;
  reason: string;
}

export interface OrderTransferredEvent extends AbstractBaseEvent {
  transferOrderId: number;
}

export interface OrderRefundedEvent extends AbstractBaseEvent {
  orderId: number;
  refundOrderId: number;
  reason: string;
}

export const REFUND_DATE_LIMIT = 14; // in days

export interface PickupTimeCount {
  count: number;
  time: string;
}

export interface StorageInvoiceModel {
  id: number;
  createDate: string;
  sendDate: string;
  receiveDate: string;
  number: string;
  incomingInvoiceNumber: string;
  incomingInvoiceDate: string;
  paymentType: PaymentType;
  totalPrice: number;
  paidTotal: number;
  status: string;
  currency: Currency;
  supplier: SupplierModel;
  store: StoreBasicModel;
  products: StorageInvoiceProductModel[];
  files: FileModel[];
  contract: ContractModel;
  dueDate: string;
  paymentDate?: string;
  spendCategory: SpendCategoryModel
}

export interface StorageInvoiceProductModel {
  id: number;
  productInfoId: number;
  unitOfMeasureTitle: UiLabel;
  title: UiLabel;
  storeId: number;
  amount: number;
  pricePerUnit: number;
  totalValue: number;
  issueDate: string;
  expiryDate: string;
  spendType: DictionaryShortEntry;
}

export interface SupplierModel {
  id: number;
  name: string;
  description: string;
  uin: string;
  countryId: number;
}

export interface SupplierFullModel {
  id: number;
  name: string;
  description: string;
  uin: string;
  country: CountryModel;
  representatives: RepresentativeModel[];
  addresses: AddressModel[];
  bankAccounts: BankAccountInfoModel[];
}

export interface RepresentativeModel {
  id?: number;
  name: string;
  position: string;
  contacts?: RepresentativeContactModel[];
}

export interface RepresentativeContactModel {
  id: number;
  representativeId: number;
  value: string;
  type: string;
}

export interface BankAccountInfoModel {
  id: number;
  bank?: BankModel;
  iban: string;
  currency: Currency;
  beneficiaryCode: string;
  default: boolean;
  containerClass: string;
  containerId: number;
}

export interface BankModel {
  id: number;
  name: string;
  address: string;
  oldBic: string;
  bic: string;
}

export interface StoreProductModel {
  id: number;
  removed: boolean;
  enabled: boolean;
  amount: number;
  forSale: boolean;
  onlineSellable: boolean;
  minOrderDay: number;
  untilOrderTime: string;
  receiveAfterTime: string;
  productInfo: ProductInfoModel;
  unitOfMeasure: UnitOfMeasureModel;
  store: StoreBasicModel;
  category: ProductCategoryModel;
}

export interface CityReservationModel {
  city: CityModel;
  storeProducts: {
    storeProduct: StoreProductModel;
    store: StoreBasicModel;
    reservedAmount: number;
  }[];
}

export interface OrderExpiration {
  status: string;
  seconds: number;
}

export interface ListWrapper<T> {
  list: T[];
}

export interface RejectionModel {
  id: number;
  createDateTime: number;
  date: string;
  cityId: number;
  storeId: number;
  clientId: number;
  productInfoIds: number[];
  decor: boolean;
  deliveryTimes: string[];
  pickupTimes: string[];
  promos: string[];
  addresses: string[];
  note: string;
  delivery: boolean;
  pickup: boolean;
  orderId: number;
}

export interface RejectionFullModel extends RejectionModel {
  store: StoreBasicModel;
  client: ClientBasicModel;
  city: CityModel;
  productDefinitions: ProductInfoShortModel[];
}

export interface OperatorOrderStatisticModel {
  actor: number;
  actorFullname: string;
  orderCreatedCount: number;
  orderModifiedCount: number;
  orderCancelledCount: number;
  orderReceivedCount: number;
}

export interface ProductDiscardInfoModel {
  date: string;
  productInfoId: number;
  productTitle: UiLabel;
  amount: number;
  totalCostPrice: number;
  totalPrice: number;
}

export function isoDate(date?: Date | number | string): string | undefined {
  if (date) {
    if (typeof date === 'string') {
      return date.substring(0, 10);
    }
    if (date) {
      return format(date, 'yyyy-MM-dd');
    }
  }
  return undefined;
}

export function uiLabel(lang: string, label?: UiLabel): string | undefined {
  if (label && (lang === 'en' || lang === 'kk' || lang === 'ru')) {
    return label[lang];
  }
  return undefined;
}

/*function paymentHolderGuard(key: string): key is keyof PaymentHolder {
  let result = false;
  for (const value of PAYMENT_KEY_MAP.values()) {
    if (value === key) {
      result = true;
    }
  }
  return result;
}*/

export function stringDateToMilli(date: string): number | undefined {
  if (date) {
    return new Date(date).getTime();
  }
  return undefined;
}

export function formatStringDate(date: string, dateFormat: string): string | undefined {
  if (date) {
    return format(new Date(date), dateFormat);
  }
  return undefined;
}

export function isMobile(): boolean {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some(toMatchItem => !!navigator.userAgent.match(toMatchItem));
}

export function getCurrencySymbol(currency?: string | Currency): string {
  if (!currency) {
    return '';
  }
  return currencyMap.get(currency as Currency)?.label ?? currency;
}

export interface CompositeProductModel {
  id: number;
  createDate: string;
  modifyDate: string;
  enabled: boolean;
  removed: boolean;
  countryId: number;
  title: UiLabel;
  description: UiLabel;
  type: CompositeProductType;
  sortOrder: number;
  price: number;
  onlineSellable: boolean;
  sellable: boolean;
  tags: string[];
  subProducts: SubProductModel[];
}

export interface OperatorActivityModel {
  onlineOperatorCount: number;
  date: string;
  user: UserBasicModel;
  asteriskId: string;
  type: 'ONLINE' | 'OFFLINE';
}

export interface SubProductModel {
  productDefinitionId: number;
  title: UiLabel;
  sortOrder: number;
  priceAmount: number;
}

export interface CompositeProductInfoModel extends CompositeProductModel {
  country: CountryModel;
  subProducts: SubProductInfoModel[];
}

export interface SubProductInfoModel extends SubProductModel {
  productDefinition: ProductInfoModel;
}

// ----------------- Price models

export interface ContractModel {
  id: number;
  number: string;
  title: string;
  note: string;
  date: string;
  organization: OrganizationModel;
  supplier: SupplierModel;
  files: FileModel[];
  currency: string;
  dueDays: number;
  bank: BankModel;
  iban: string;
}

export interface ProductReleaseReportModel {
  productTitle: UiLabel;
  productExt1cCode: string;
  unitOfMeasureTitle: UiLabel;
  productAmount: number;
}

export interface AppPropertyBasicModel {
  id: number;
  enabled: boolean;
  key: string;
  value: object;
}

export interface ReportProductModel {
  productTitle: UiLabel;
  productExt1cCode: string;
  unitOfMeasureTitle: UiLabel;
  payments: PaymentInfoModel[];
  costPrice: number;
  storeId: number;
}

export interface PaymentHolder {
  cash?: number;
  pos?: number;
  epay?: number;
  forte?: number;
  rahmet?: number;
  senim?: number;
  checkingAccount?: number;
  kaspi?: number;
  halyk?: number;
  kaspiPos?: number;
  paybox?: number;
  uzPaybox?: number;
  debt?: number;
  coin?: number;
}

export const PAYMENT_KEY_MAP = new Map<PaymentType, keyof PaymentHolder>([
  [PaymentType.CASH, 'cash'],
  [PaymentType.POS, 'pos'],
  [PaymentType.FORTE, 'forte'],
  [PaymentType.RAHMET, 'rahmet'],
  [PaymentType.SENIM, 'senim'],
  [PaymentType.CHECKING_ACCOUNT, 'checkingAccount'],
  [PaymentType.KASPI, 'kaspi'],
  [PaymentType.HALYK, 'halyk'],
  [PaymentType.KASPI_POS, 'kaspiPos'],
  [PaymentType.PAYBOX, 'paybox'],
  [PaymentType.UZ_PAYBOX, 'uzPaybox'],
  [PaymentType.DEBT, 'debt'],
  [PaymentType.COIN, 'coin'],
]);

export function resetPaymentHolder(paymentHolder: PaymentHolder | null | undefined) {
  if (!paymentHolder) {
    return;
  }
  for (const paymentKey of PAYMENT_KEY_MAP.values()) {
    paymentHolder[paymentKey] = 0;
  }
}

export function populatePaymentHolder(paymentHolder: PaymentHolder | null | undefined, payments: { type: PaymentType, value: number }[] | undefined | null) {
  if (!paymentHolder || payments === null || payments === undefined || payments.length === 0) {
    return;
  }
  payments
    .filter(p => p.value > 0)
    .forEach(p => {
      const key = PAYMENT_KEY_MAP.get(p.type);
      if (key) {
        paymentHolder[key] = p.value;
      }
    });
}

export function toPaymentArray(paymentHolder: PaymentHolder) {
  return Object.entries(paymentHolder)
    .filter(([key, value]) => paymentHolderGuard(key) && value !== undefined && value !== null && value !== 0)
    .map(([key, value]) => ({type: toSnakeCase(key).toUpperCase(), value: value} as PayModel));
}

function paymentHolderGuard(key: string): key is keyof PaymentHolder {
  let result = false;
  for (const value of PAYMENT_KEY_MAP.values()) {
    if (value === key) {
      result = true;
    }
  }
  return result;
}

export interface DebtModel {
  debtTotal: number;
  discardTotal: number;
  user: UserBasicModel;
}

export interface UserReportModel {
  id: number;
  phone: string;
  firstname: string;
  lastname: string;
  patronymic: string;
  keycloakId: string;
  fullname: string;
  shortname: string;
}

export interface FeedbackStoreRankingModel {
  storeTitle: UiLabel;
  rating: number;
  cityTitle: UiLabel;
  startWorkDate: string;
  endWorkDate: string;
}

export interface FeedbackTypeRankingModel {
  storeTitle: UiLabel;
  type: string;
  rating: number;
}

export interface OperatorRankingModel {
  operatorFio: string;
  rating: number;
}

export interface RecipeInfoModel {
  id: number;
  netAmount: number;
  grossAmount: number;
  group: number;
  priority: number;
  coefficient: number;
  to: number;
  type: RecipeType;
  productInfo: ProductInfoModel;
  workflowStep: WorkflowStepModel;
  unitOfMeasure: UnitOfMeasureModel;
}

export interface ProductInfoModel {
  id: number;
  enabled: boolean;
  removed: boolean;
  createDate: string;
  modifyDate: string;
  modifyUserId: number;
  title: UiLabel;
  categoryId: number;
  type: ProductInfoType;
  decored: boolean;
  globalForSale: boolean;
  cfOutputProduct: boolean;
  description: UiLabel;
  toCbInvoice: boolean;
  weight: number;
  dynamicWeight: boolean;
  unitOfMeasureId: number;
  kind: KindType;
  composition: UiLabel;
  onlineSellable: boolean;
  fullTitle: UiLabel;
  unlimited: boolean;
  purchasable: boolean;
  brandId: number;
  spendCategoryId: number;
}

export interface RecipeModel {
  id: number;
  netAmount: number;
  grossAmount: number;
  group: number;
  priority: number;
  coefficient: number;
  type: RecipeType;
  productInfoId: number;
  containerId: number;
  containerClass: string;
  toUnitOfMeasure: number;
  productUnitOfMeasure: number;
}

export interface WorkflowStepModel {
  id: number;
  title: UiLabel;
  description: string;
  workflow: WorkflowModel;
  order: number;
  servicePrice: number;
}

export interface WorkflowModel {
  id: number;
  title: UiLabel;
  description: string;
  workflowCategoryId: number;
  stores: StoreBasicModel[];
}

export interface WorkflowCategoryModel {
  id: number;
  title: UiLabel;
  seq: number;
  brandId: number;
}

export interface UserReceivedOrderModel {
  userFullname: string;
  deliveryAmount: number;
  pickupAmount: number;
  refundAmount: number;
}

export interface AtolRequestResult<T> {
  results: {
    error: {
      code: number;
      description: string;
    };
    status: string;
    result: T
  }[];
}

export interface AtolRequestAddResult {
  number: number; // Порядковый номер задания в очереди example: 1
  uuid: string; // Присвоенный заданию UUID (используется UUID задания)  example: 0ba40014-5fa5-11ea-b5e9-037d4786a49d
  isBlocked: boolean; // Признак блокировки очереди example: false
  blockedUUID: string; // example: UUID задания, вызвавшего блокировку очереди
}


export interface AtolDeviceInfo {
  firmwareVersion: string;      // Версия прошивки // example: 1245
  appVersion: string;           // Версия ПО ККТ // example: 5.7.0
  bootVersion: string;          // Версия загрузчика // example: 5.7.0
  scriptsVersion: string;       // Версия движка шаблонов // example: 3
  scriptsName: string;          // Наименовние загруженных шаблонов // example: ATCP3003
  model: number;                // Код модели // example: 69
  modelName: string;            // Наименование модели // example: АТОЛ 77Ф
  receiptLineLength: number;    // Ширина чековой ленты в символах для шрифта по умолчанию // example: 48
  receiptLineLengthPix: number; // Ширина чековой ленты в пикселях // example: 576
  serial: string;               // Заводской номер ККТ // example: 00106900000014
  ffdVersion: string;           // Версия ФФД
  fnFfdVersion: string;	        // Версия ФФД ФН:
}

export interface AtolDeviceStatus {
  currentDateTime: string;      // ($date-time) Текущие дата и время
  shift: string;                // Состояние смены: closed - закрыта, opened - открыта, expired - истекла (превысила 24 часа), unknown - ошибка
  blocked: boolean;             // ККТ заблокирована
  coverOpened: boolean;         // Состояние крышки
  paperPresent: boolean;        // Наличие бумаги
  fiscal: boolean;              // ККТ фискализирована
  fnFiscal: boolean;            // ФН фискализирован
  fnPresent: boolean;           // ФН обнаружен
  cashDrawerOpened: boolean;    // Денежный ящик открыт
}

export interface MoneyFlowInfoModel {
  date: string;
  totalReceive: number;
  totalRefund: number;
  totalInvoice: number;
}

export interface MoneyFlowModel {
  store: StoreBasicModel;
  moneyFlows: MoneyFlowInfoModel[];
}

export interface DetailedRecipeModel {
  title: UiLabel;
  netAmount: number;
  grossAmount: number;
  balanceAmount: number;
  recipeUnitOfMeasure: UiLabel;
  balanceUnitOfMeasure: UiLabel;
  group: number;
  priority: number;
  coefficient: number;
  to: number;
  unlimited: boolean;
  type: string;
  invoiceAmount: number;
  productInfoId: number;
  modifyDate: Date;
}

export interface DebtPropertyModel {
  allow: boolean;
}

export interface OrganizationProperty {
  paymentDestinationCode?: string;
  postalIndex?: string;
  directorId?: number;
  accountantId?: number;
  uin?: string;
  uinName?: UiLabel;
  taxType?: string;
  taxSeries?: string;
  taxNumber?: string;
  legalAddress?: string;
  ofdVerifierInfo?: string;
  storeDirectorIdMap?: object;
}

export interface CallStatusInHourModel {
  time: string;
  incomingCount: number;
  waitingCount: number;
  ringingCount: number;
  talkingCount: number;
  finishedCount: number;
}

export interface OperatorKpiModel {
  operator: UserBasicModel;
  date: string;
  callCount: number;
}

export interface BarData {
  date: string;

  [operatorId: string]: string;
}

export interface PieData {
  name: string;
  value: number;
  color?: string;
}

export interface CallStatusModel {
  status: CallStatus;
  date: string;
  sum: number;
}

export interface SourceSystemModel {
  sourceSystem: SourceSystem;
  date: string;
  count: number;
}

export interface ProfileKpiModel {
  userId: number;
  firstname: string;
  lastname: string;
  modifyDate: string;
  profileCount: number;
}

export function getPaymentValue(payments: { type: PaymentType, value: number }[] | undefined | null, paymentType: PaymentType): number {
  if (payments === null || payments === undefined) {
    return 0;
  }
  return payments.find(p => p.type === paymentType)?.value ?? 0;
}

export function getPaymentTotalAmount(payments: PaymentInfoModel[] | undefined | null): number {
  if (payments === null || payments === undefined) {
    return 0;
  }
  return toFixed(payments.map(p => p.amount ?? 0).reduce((a, b) => a + b, 0) ?? 0, 3);
}

export function getPaymentTotalValue(payments: PaymentInfoModel[] | undefined | null): number {
  if (payments === null || payments === undefined) {
    return 0;
  }
  return toFixed(payments.map(p => p.value).reduce((a, b) => a + b, 0) ?? 0, 3);
}

export function getPaymentTotalDiscount(payments: PaymentInfoModel[] | undefined | null): number {
  if (payments === null || payments === undefined) {
    return 0;
  }
  return toFixed(payments.map(p => p.discount ?? 0).reduce((a, b) => a + b, 0) ?? 0, 3);
}

export function formatDate(date: Date | string, dateFormat: string): string | undefined {
  let newDate;
  if (date) {
    if (typeof date === 'string') {
      newDate = new Date(date);
    } else {
      newDate = date;
    }
    return format(newDate, dateFormat);
  }
  return undefined;
}

export const langMap = new Map<string, string[]>([
  ['ru', ['ru']],
  ['kz', ['ru', 'kk']],
  ['kg', ['ru']],
  ['uz', ['ru', 'uz']],
  ['en', ['en', 'ru']],
]);

export const domainLocaleMap = new Map<string, string>([
  ['ru', 'ru'],
  ['kk', 'kz'],
  ['kg', 'kg'],
  ['uz', 'uz'],
  ['en', 'en'],
]);

export const phoneMaskMap = new Map<string, string>([
  ['ru', '000 000-00-00'],
  ['kz', '(000) 000-00-00'],
  ['kg', '000-000000'],
  ['uz', '(00) 000-00-00'],
  ['en', '000 000 00 00'],
]);

export const phonePrefixMap = new Map<string, string>([
  ['ru', '+7 '],
  ['kz', '+7 '],
  ['kg', '+996 '],
  ['uz', '+998 '],
  ['en', '+1 '],
]);

export interface CurrencyInfo {
  label: string;
  short: string;
  full: string;
}

export const currencyMap = new Map<Currency, CurrencyInfo>([
  [Currency.KZT, {label: '₸', short: 'тг.', full: 'теңге'}],
  [Currency.USD, {label: '$', short: 'dol.', full: 'dollar'}],
  [Currency.KGS, {label: 'c', short: 'с', full: 'сом'}],
  [Currency.RUB, {label: '₽', short: 'руб.', full: 'рубль'}],
  /*[Currency.EUR, {label: '€', short: 'eur', full: 'euro'}],
  [Currency.AZN, {label: '₼', short: 'man.', full: 'manat'}],*/
  [Currency.UZS, {label: 'so\'m', short: 'so\'m.', full: 'so\'m'}]
]);

export const abbrMap = new Map<string, string>([
  ['улица', 'ул.'],
  ['проспект', 'пр.'],
  ['бульвар', 'б.'],
  ['переулок', 'пер.'],
  ['микрорайон', 'мкр.'],
  ['район', 'р-н'],
  ['шоссе', 'ш.'],
]);

export function getCurrency(currency: Currency): CurrencyInfo | undefined {
  return currencyMap.get(currency);
}

export interface ConfectionaryProductModel {
  id: number;
  createDate: string;
  modifyDate: string;
  modifyUserId: number;
  userId: number;
  userFullname: string;
  workflowId: number;
  workflowName: string;
  workflowStepId: number;
  workflowStepName: string;
  productInfoId: number;
  productInfoTitle: UiLabel;
  totalAmount: number;
  weight: number;
  storeId: number;
  totalCostPrice: number;
  totalServicePrice: number;
}

export interface StorageFlowModel {
  title: UiLabel;
  uomTitle: UiLabel;
  storeProductId: number;
  startBalance: number;
  endBalance: number;
  purchase: number;
  discard: number;
  invReceive: number;
  receive: number;
  refund: number;
  add: number;
  revoke: number;
  importProduct: number;
  exportProduct: number;
}

export interface SupplierPaymentModel {
  supplierId: number;
  supplierName: string;
  supplierUin: string;
  supplierBankAccountBalance: number;
  storeId: number;
  storeName: UiLabel;
  payments: SupplierPaymentInfoModel[];
  credit: number;
  debit: number;
}

export interface SupplierPaymentInfoModel {
  date: string;
  waitPaymentTotal: number;
  total: number;
}

export function moneyFormat(num?: number): string {
  if (!num) {
    return '0';
  }
  const parts = (num ?? 0).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

export function debtAllowed(discountResult: DiscountResultModel): boolean {
  if (!discountResult.enabled || discountResult.removed) {
    return false;
  }
  return discountResult.promoConditions.some(cnd => cnd.type === ConditionType.EMPLOYEE && (cnd as EmployeeCondition).debtAllowed);
}

export class NotifierEventBusAddress {
  static readonly NOTIFICATIONS = 'notifications';

  static readonly WS_USER_PREFIX = 'ws-user';
  static readonly WS_STORE_PREFIX = 'ws-store';
  static readonly WS_CLIENT_PREFIX = 'ws-client';
  static readonly REMAINDER_PREFIX = 'remainder';
  static readonly CITY_REMAINDER_PREFIX = 'city-remainder';
  static readonly ORDER_PREFIX = 'order';
  static readonly DELIVERY_TIME_PREFIX = 'ws-delivery-time';
  static readonly SPLIT_PRODUCT_PREFIX = 'ws-split-product';

  static user(phone: string): string {
    return `${NotifierEventBusAddress.WS_USER_PREFIX}-${phone}`;
  }

  static store(storeId: number): string {
    return `${NotifierEventBusAddress.WS_STORE_PREFIX}|${storeId}`;
  }

  static client(phone: string): string {
    return `${NotifierEventBusAddress.WS_CLIENT_PREFIX}-${phone}`;
  }

  static orderEvent(orderId: number): string {
    return `${NotifierEventBusAddress.ORDER_PREFIX}-${orderId}`;
  }

  static deliveryTime(date: string, storeId: number): string {
    return `${NotifierEventBusAddress.DELIVERY_TIME_PREFIX}|${storeId}|${date}`;
  }

  static remainder(storeId: number, date: string): string {
    return `${NotifierEventBusAddress.REMAINDER_PREFIX}|${storeId}|${date}`;
  }

  static cityRemainder(cityId: number, date: string): string {
    return `${NotifierEventBusAddress.CITY_REMAINDER_PREFIX}|${cityId}|${date}`;
  }

  static splitProduct(date: string, storeId: number): string {
    return `${NotifierEventBusAddress.SPLIT_PRODUCT_PREFIX}|${storeId}|${date}`;
  }
}

export type Action =
  'orderPaid'
  | 'promoConfirmed'
  | 'promoRejected'
  | 'curbsidePickupDeactivated'
  | 'curbsidePickupSeen'
  | 'curbsidePickupCreated'
  | 'cashboxStateUpdated'
  | 'refreshCashboxState'
  | 'orderCancelled'
  | 'orderReceived';

export interface BrandModel {
  id: number;
  createDate: string;
  title: string;
}

export interface BrandTitleModel {
  id: number;
  createDate: string;
  title: UiLabel;
  brand: BrandModel;
  country: CountryModel;
}

export interface CategoryProduct {
  categoryId: number;
  categoryTitle: UiLabel;
  date: string;
  productCount: number;
}


export type ClassName = 'Brand' | 'ProductCategory' | 'Order' | 'Product' | 'Group' | 'User' | 'Invoice';

export interface InvoiceContractProperty {
  required: boolean;
}

export interface CompanyModel {
  uin: string;
  title: string;
  address: string;
  contract: string;
}

export interface AuthorityModel {
  representativeFullname: string;
  letterOfAuthorityDate: string;
  letterOfAuthorityNumber: string;
  letterOfAuthorityFileId: number;
}

export const CASHBOX_SETTINGS_LOCALPRINTNOTE = 'hc-print-note';

export interface PaymentApprovalModel {
  id: number;
  store: StoreBasicModel;
  supplier: SupplierModel;
  bankAccount: BankAccountInfoModel;
  date: string;
  number: string;
  total: number;
  note: string;
  orgBankAccount: BankAccountInfoModel;
  status: PaymentApprovalStatus;
  paymentDestinationCode: string;
  invoicePayments: InvoicePaymentModel[];
  cancelReason: string;
  paymentDate: string;
}

export interface InvoicePaymentModel {
  id: number;
  paymentApprovalId: number;
  invoice: StorageInvoiceModel;
  value: number;
}

export interface BankAccountRequest {
  id: number;
  bankId: number;
  iban: string;
  currency: Currency;
  beneficiaryCode: string;
  isDefault: boolean;
  containerId: number;
}

export interface ContractRequest {
  number: string;
  date: string;
  title: string;
  note: string;
  supplierId: number;
  fileIds: number[];
  dueDays: number;
  currency: string;
  organizationId: number;
  bankId: number;
  iban: string;
}

export interface PaymentApprovalFromReportModel {
  supplierId: number;
  supplierName: string;
  storeId: number;
  storeTitle: string;
  dueDateFrom: string;
  dueDateTo: string;
  createDateFrom: string;
  createDateTo: string;
}

export interface DictionaryShortEntry {
  code: string;
  title: UiLabel;
}

export interface UserComponentModel {
  id: number;
  fullname: string;
  owner: boolean;
  phone: string;
}

export interface ProductSplitDefinitionModel {
  id: number;
  inProductInfoId: number;
  outProductInfoId: number;
  splitQuantity: number;
  shape: string;
  inProductSize: string;
}

export interface ProductSplitDefinitionFullModel {
  id: number;
  inProductInfo: ProductInfoModel;
  outProductInfo: ProductInfoModel;
  splitQuantity: number;
  shape: string;
  inProductSize: string;
}

export interface ProductSplitAvailableShapeModel {
  currentShape: string;
  shapes: string[][];
}

export interface DatePropertyModel {
  id: number;
  storeId: number;
  data: PropertyData; // PropertyDto
  date: string;
  checksum: string;
  storeWorkDate: StoreWorkTimeModel;
}

export interface PropertyData {
  plans: StorePlan[];
  categories: ProductCategoryModel[];
  decor: DecorModel;
  inscription: InscriptionModel
  onlineSellable: {
    enabled: boolean;
  };
}

export interface StorePlan {
  productInfoIds: number[];
  plan: number;
  limit: number;
}

export interface EmployeeContractFullModel {
  id: number;
  store: StoreBasicModel;
  user: UserBasicModel;
  type: EmployeeType;
  fromDate: string;
  toDate: string;
  accrualType: AccrualType;
  accrualValue: number;
  bonus: number;
  bonusSalesPercent: number;
  bonusDeliveryValue: number;
  amortizationValue: number;
}

export interface EmployeeContractModel {
  id: number;
  storeId: number;
  userId: number;
  type: EmployeeType;
  fromDate: string;
  toDate: string;
  accrualType: AccrualType;
  accrualValue: number;
  bonus: number;
  bonusSalesPercent: number;
  bonusDeliveryValue: number;
  amortizationValue: number;
}

export interface StoreMoneyInfoModel {
  store: StoreBasicModel;
  cashBalance: number;
  bankAccountBalance: number;
}

export interface StoreEncashmentModel {
  id: number;
  createDate: string;
  date: string;
  store: StoreBasicModel;
  collectorFullname: string;
  user: UserBasicModel;
  balance: number;
  afterBalance: number;
  beforeBalance: number;
  note: string;
  accountType: AccountType;
  operationType: EncashmentOperationType;
}
